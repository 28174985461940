import { BaseEntityModel } from '@/model/base-model';
import { FormControl, FormControlType, FormControlTextModel, FormControlOptionModel, FormControlModel } from '@/model/form-control';
import { QueryPageModel } from '@/model/query-model';
import { QueryControl, QueryControlType } from '@/model/query-control';
import ParkingEntranceOrExitService from '../service/parking-entrance-or-exit';
import ParkingFacilityService from '../service/parking-facility';
import { SubSystemType } from '@/model/enum';

export class ParkingEntranceOrExitEntityModel extends BaseEntityModel {
    @FormControl({
        label: '名称',
        type: FormControlType.TEXT,
        required: true
    } as FormControlTextModel)
    name: string = undefined;

    @FormControl({
        label: '类型',
        type: FormControlType.SELECT,
        optionsPromise: ParkingEntranceOrExitService.getEntityType,
        required: true,
        cascad: true
    } as FormControlOptionModel)
    typeId: string = undefined;
    typeName: string = undefined;

    @FormControl({
        label: '空间位置',
        type: FormControlType.TREE_SELECT,
        // optionsPromise: SpaceV2Service.getLocationTree,
        optionsPromise: ParkingFacilityService.getLocationTree,
        optionsPromiseParam: SubSystemType.PARKING,
        required: false
    } as FormControlModel<string>)
    locationId: string = undefined;
    locationName: string = undefined;

    attributes: Array<any> = undefined;

    // @FormControl({
    //     label: '入口闸机',
    //     type: FormControlType.SELECT
    // } as FormControlOptionModel)
    // deviceEntrance: string = undefined;

    // @FormControl({
    //     label: '出口闸机',
    //     type: FormControlType.SELECT
    // } as FormControlOptionModel)
    // deviceExit: string = undefined;

    // @FormControl({
    //     label: '监控',
    //     type: FormControlType.SELECT
    // } as FormControlOptionModel)
    // deviceCamera: string = undefined;

    static getTableColumns() {
        return [
            {
                title: '出入口名称',
                dataIndex: 'name',
                scopedSlots: { customRender: 'name' }
            },
            {
                title: '类型',
                dataIndex: 'typeName'
            },
            {
                title: '位置',
                dataIndex: 'locationName'
            },
            {
                title: '创建时间',
                dataIndex: 'createdTime'
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        return this;
    }

    toService() {
        const data: any = super.toService();
        return data;
    }
}

export class ParkingEntranceOrExitQueryModel extends QueryPageModel {
    @QueryControl({
        label: '类型',
        placeholder: '请选择类型,支持多选',
        type: QueryControlType.SELECT,
        optionsPromise: ParkingEntranceOrExitService.getEntityType,
        hasAllOption: true,
        mode: 'default',
        span: 10
    })
    type: string = JTL.CONSTANT.ALL_OPTION.value;

    @QueryControl({
        label: '名称',
        type: QueryControlType.TEXT,
        span: 10
    })
    name: string = undefined;

    toService() {
        // const data: any = super.toService();
        // return data;
        return {
            params: {
                types: this.type ? [this.type] : undefined,
                name: this.name
            }
        };
    }
}
