


















import { Component, Ref } from 'vue-property-decorator';
import FormDialogComponent from '@/mixins/form-dialog-component';
import { FormControlOptionModel, FormControlType } from '@/model/form-control';
import ParkingEntranceOrExitService from './service/parking-entrance-or-exit';
import FormComponent from '@/mixins/form-component';
import { ParkingEntranceOrExitEntityModel } from './model/parking-entrance-or-exit-entity';
import { ViewModeType } from '@/model/enum';
import FacilityV2Service from '@/service/facility-v2';
import { ICRUDQ } from '@/model/interface';

@Component
export default class ParkingEntranceOrExitDialog extends FormDialogComponent<ParkingEntranceOrExitEntityModel> {
    @Ref('extraForm')
    extraForm: FormComponent<any>;

    formOnChange(formControlModel: FormControlOptionModel) {
        if (formControlModel.key === 'typeId' && this.jtlForm.formModel.typeId) {
            ParkingEntranceOrExitService.getExitEntranceGateAttributes(this.jtlForm.formModel.typeId).then(res => {
                this.initExtraForm(res);
            });
        }
    }

    initExtraForm(attributes: Array<any>) {
        const controls = _.map(attributes, item => {
            return {
                key: item.identifier,
                value: item.value,
                label: item.name,
                type: FormControlType.SELECT,
                mode: 'multiple',
                optionsPromise: FacilityV2Service.queryByFacilityType,
                optionsPromiseParam: item.entityTypeId
            };
        });
        this.extraForm.initFormByControls(controls, ViewModeType.UPDATE);
    }

    vehicleDialogOpen(model: ParkingEntranceOrExitEntityModel, service: ICRUDQ<ParkingEntranceOrExitEntityModel, any>, viewMode: ViewModeType, needGetDetail: boolean = false): void {
        this.dialogOpen(model, service, viewMode, needGetDetail)?.then(res => {
            this.$nextTick(() => {
                this.initExtraForm(res.attributes);
            });
        });
    }

    vehicleDialogOK() {
        this.jtlForm.formModel.attributes = _.map(_.keys(this.extraForm.formModel), (key, value) => {
            return {
                identifier: key,
                value: this.extraForm.formModel[key]
            };
        });
        this.dialogOK();
    }
}
