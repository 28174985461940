































































import { Component, Mixins } from 'vue-property-decorator';
import TableDialogFormComponent from '@/mixins/table-dialog-form-component';
import DrawerComponent from '@/mixins/drawer-component';
import ParkingEntranceOrExitService from './service/parking-entrance-or-exit';
import { dateFormat } from '@/filter';

@Component
export default class ParkingEntranceOrExitDrawer extends Mixins(TableDialogFormComponent, DrawerComponent) {
    viewModel: any = {};
    cameraColumns: Array<any> = [
        {
            title: '名称',
            dataIndex: 'vals[0].name',
            scopedSlots: { customRender: 'name' }
        },
        {
            title: '状态',
            dataIndex: 'vals[0].val',
            scopedSlots: { customRender: 'val' }
        },
        {
            title: '',
            dataIndex: 'vals[0].lastUpdateTs',
            scopedSlots: { customRender: 'action' }
        }
    ];

    otherColumns: Array<any> = [
        {
            title: '名称',
            dataIndex: 'name',
            scopedSlots: { customRender: 'name' }
        },
        {
            title: '数值',
            dataIndex: 'val'
        },
        {
            title: '更新时间',
            dataIndex: 'lastUpdateTs',
            customRender: (text, record, index) => {
                return dateFormat(text);
            }
        }
    ];

    drawerViewOpen(data, title) {
        this.drawerOpen(data, title);
        ParkingEntranceOrExitService.gateDetail(data).then(res => {
            this.viewModel = res;
        });
    }
}
