
import { ParkingEntranceOrExitEntityModel, ParkingEntranceOrExitQueryModel } from '@/pages/sub-system/model/parking-entrance-or-exit-entity';
import { ICRUDQ } from '@/model/interface';
import { get, post, del } from '@/service/request';

const URL_PATH = `${BI_BASE_REQUEST_PATH}/parkingSystem/parkingGate`;

class ParkingEntranceOrExitService implements ICRUDQ<ParkingEntranceOrExitEntityModel, ParkingEntranceOrExitQueryModel> {
    async create(model: ParkingEntranceOrExitEntityModel):Promise<ParkingEntranceOrExitEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<ParkingEntranceOrExitEntityModel> {
        const url = `${URL_PATH}/detail/${modelId}`;
        const res = await get(url);
        return new ParkingEntranceOrExitEntityModel().toModel(res);
    }

    async update(model: ParkingEntranceOrExitEntityModel):Promise<ParkingEntranceOrExitEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: ParkingEntranceOrExitEntityModel):Promise<ParkingEntranceOrExitEntityModel> {
        const url = `${URL_PATH}/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: ParkingEntranceOrExitQueryModel, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/query`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new ParkingEntranceOrExitEntityModel().toModel(item));
        return res;
    }

    async getEntityType():Promise<any> {
        const url = `${BI_BASE_REQUEST_PATH}/parkingSystem/bizEntityType/all`;
        const res = await get(url);
        return _.map(res, item => {
            return {
                name: item.name,
                // value: item.identifier,
                value: item.id
                // id: item.id
            };
        });
    }

    async getExitEntranceGateAttributes(typeId: string):Promise<any> {
        const url = `${BI_BASE_REQUEST_PATH}/parkingSystem/bizEntityType/${typeId}/attributes`;
        const res = await get(url);
        return res;
    }

    async gateDetail(id: string):Promise<any> {
        const url = `${BIBIZ_BASE_REQUEST_PATH}/parkingSystem/parkingGate/detail/${id}`;
        const res = await get(url);
        const cameraList = _.filter(res.attrs, item => item.identifier === 'Camera');
        const attrs = _.filter(res.attrs, item => item.identifier !== 'Camera');
        return { cameraList, attrs, basic: res.basic };
    }
}

export default new ParkingEntranceOrExitService();
